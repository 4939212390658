import Header from '@/features/user/Header'
import Navigation from '@/features/common/navigation'
import { useTranslation } from 'react-i18next';

import { useEffect, useState } from 'react';
import "./Alert.css"
import { useAppDispatch, useAppSelector } from '@/app/hooks';
import { GetAlert, GetNotification, initAlert, selectAlerts, selectLoading, selectLoadNotice, selectNotice } from '@/features/alert/alertSlice';
import AlertSetting from "./Setting"
import AlertNotice from "./Notice"
import Paging from '@/features/common/paging';
import searchImg from "@/assets/search.png"
import LoadingIcon from "@/features/common/loading"


export default function AlertPage () {
  const { t } = useTranslation()
  const dispatch = useAppDispatch()
  const [dataType, setDataType] = useState(0)
  const [keyword, setKeyword] = useState("")
  const [page, setPage] = useState(0)
  const loading = useAppSelector(selectLoading)
  const loadNotification = useAppSelector(selectLoadNotice)
  const alerts = useAppSelector(selectAlerts)
  const notice = useAppSelector(selectNotice)
  const data = dataType === 0 ? alerts : notice

  document.title = "Alert Notifications"

  useEffect(() => {
    if (!alerts) {
      dispatch(initAlert())
    }
  })

  const changeType = (type: number) => {
    setDataType(type)
    if (type === 0) {
      dispatch(GetAlert({offset: 0, limit: 4}))
    } else {
      dispatch(GetNotification({offset: 0, limit: 4}))
    }
    setPage(0)
  }

  const changePage = (page: number) => {
    if (dataType === 0) {
      dispatch(GetAlert({
        offset: (page - 1) * 4,
        limit: 4,
      }))
      setKeyword("")
    } else {
      dispatch(GetNotification({
        offset: (page - 1) * 4,
        limit: 4,
      }))
      setKeyword("")
    }
    
    setPage(page - 1)
  }

  const changeKeyword = (keyword: string) => {
    if (dataType === 0) {
      dispatch(GetAlert({
        offset: page * 4,
        limit: 4,
        address: keyword
      }))
    } else {
      dispatch(GetNotification({
        offset: page * 4,
        limit: 4,
        address: keyword
      }))
    }

    setKeyword(keyword)
  }

  return (
    <>
      <Header />
      <div className="alert-page-container body-container">

        <Navigation id={t("alertPage.title")} />

        <div className="container-item list-container">

          { (loading || loadNotification) ? <div className="container-loadicon"><LoadingIcon size={20} color={"#4C6FFF"} /></div> : null }

          <div className="address-search">
            <img alt="" src={searchImg} />
            <input type="text" value={keyword} onChange={(e) => changeKeyword(e.target.value)} placeholder={t("alert.search")} />
          </div>

          <nav className="nav-list">
            <li className={dataType === 0 ? "active": ''} onClick={() => changeType(0)}>{t("alertPage.setting")}</li>
            <li className={dataType === 1 ? "active": ''} onClick={() => changeType(1)}>{t("alertPage.notice")}</li>
          </nav>

          <div className="table-wrap">
            {
              dataType === 0 ? <AlertSetting uploadData={() => changePage(page+1) } /> : <AlertNotice uploadData={() => changePage(page+1) } />
            }
          </div>

          <div className="paging-wrap">
            <Paging total={data ? data.total: 0} page={page + 1} showCount={4} onChange={(page: number) => changePage(page)} />
          </div>
        </div>
        
      </div>
    </>
  );
}

