import { checkEmail, checkPass } from "@/app/util";
import { backendApi } from "@/request";
import { t } from "i18next";
import { resolve } from "path";

export interface CaseItem {
  id: number;
  name: string;
  description: string;
  credit: number;
  status: string;
}
// 获取案件列表
export function GetCaseList() {
  return new Promise<Array<CaseItem>>(async (resolve, reject) => {
    const data: any = await backendApi.get("/case/all/approved");
    resolve([
      {
        id: 0,
        name: "0",
        description: "",
        credit: 0,
        status: "APPROVED",
      },
      ...data.map((i: any) => ({
        id: i.id,
        name: i.name,
        description: i.description,
        credit: i.credit,
        status: i.status,
      })),
    ]);
  });
}

export interface LoginParameter {
  email: string;
  password: string;
  caseName: string;
}

export enum LoginStatus {
  NORMAL = 0,
  PROCESSING = 1,
  SUCCESS = 2,
  MOBILE_ERROR = 3,
  EMAIL_ERROR = 4,
  PASSWORD_ERROR = 5,
  UNKNOW_CASE_NAME = 7,
  OTHER_ERROR = 6,
}

export interface LoginResponse {
  status: LoginStatus;
  message?: string;
  token?: string;
}

export function Login(params: LoginParameter) {
  return new Promise<LoginResponse>(async (resolve, reject) => {
    if (params.caseName.trim().length === 0)
      return resolve({ status: LoginStatus.UNKNOW_CASE_NAME });
    if (!checkEmail(params.email))
      return resolve({ status: LoginStatus.EMAIL_ERROR });
    if (!checkPass(params.password))
      return resolve({ status: LoginStatus.PASSWORD_ERROR });

    const r: any = await backendApi.post("/user/login", {
      caseId: params.caseName,
      email: params.email,
      password: params.password,
    });

    // 非成功状态判断
    if (!r.success) {
      if (r.code === 40004)
        return resolve({
          status: LoginStatus.PASSWORD_ERROR,
        });
      return resolve({
        status: LoginStatus.OTHER_ERROR,
        message: r.message,
      });
    }

    resolve({
      status: LoginStatus.SUCCESS,
      token: r.data.token,
    });
  });
}

export enum RegisterStatus {
  NORMAL = 0,
  PROCESSING = 1,
  SUCCESS = 2,
  EMAIL_ERROR = 15,
  PASSWORD_ERROR = 16,
  CONFIRM_PASSWORD_ERROR = 17,
  NAME_ERROR = 4,
  TELEPHONE_ERROR = 5,
  UNIT_ERROR = 7,
  UNKNOW_CASE_NAME = 11,
  POLICE_POSITIVE_ERROR = 13, //正面
  POLICE_REVERSE_ERROR = 14, // 反面
  SEAL_ERROR = 15, // 盖章
  OTHER_ERROR = 6, // 其他错误
}
export interface RegisterResponse {
  status: RegisterStatus;
  message?: string;
}

export interface RegisterParameter {
  confirmPass: string;
  email: string;
  password: string;
  name: string;
  telephone: string;
  unit: string;
  caseName: string;
  policePositive: number;
  policeReverse: number;
  seal: Array<number>;
}
export function Register(params: RegisterParameter) {
  return new Promise<RegisterResponse>(async (resolve, reject) => {
    if (!checkEmail(params.email))
      return resolve({ status: RegisterStatus.EMAIL_ERROR });
    if (!checkPass(params.password))
      return resolve({ status: RegisterStatus.PASSWORD_ERROR });
    if (params.password != params.confirmPass)
      return resolve({ status: RegisterStatus.CONFIRM_PASSWORD_ERROR });
    if (params.name.trim().length === 0)
      return resolve({ status: RegisterStatus.NAME_ERROR });
    if (
      params.telephone.trim().length < 8 ||
      params.telephone.trim().length > 20
    )
      return resolve({ status: RegisterStatus.TELEPHONE_ERROR });
    if (params.unit.trim().length === 0)
      return resolve({ status: RegisterStatus.UNIT_ERROR });
    if (params.caseName.trim().length === 0)
      return resolve({ status: RegisterStatus.UNKNOW_CASE_NAME });
    if (params.policePositive === 0)
      return resolve({ status: RegisterStatus.POLICE_POSITIVE_ERROR });
    if (params.policeReverse === 0)
      return resolve({ status: RegisterStatus.POLICE_REVERSE_ERROR });

    const r: any = await backendApi.post("/user/register", {
      email: params.email,
      password: params.password,
      name: params.name,
      landline: params.telephone,
      workUnit: params.unit,
      frontDocumentId: params.policePositive,
      backDocumentId: params.policeReverse,
      documentIds: params.seal,
      caseId: params.caseName,
    });
    if (!r.success)
      return resolve({
        status: RegisterStatus.OTHER_ERROR,
        message: r.message,
      });
    resolve({
      status: RegisterStatus.SUCCESS,
    });
    resolve({
      status: RegisterStatus.SUCCESS,
    });
  });
}

export enum ForgotPassStatus {
  NORMAL = 0,
  PROCESSING = 1,
  SUCCESS = 2,
  EMAIL_ERROR = 4,
  PASSWORD_ERROR = 5,
  CONFIRM_PASSWORD_ERROR = 7,
  UNKNOW_CASE_NAME = 8,
  BAD_CODE = 9,
  OTHER_ERROR = 6,
}
export interface ForgotPassResponse {
  status: ForgotPassStatus;
  message?: string;
}
export interface ForgotPassParameter {
  email: string;
  password: string;
  caseName: string;
  confirmPass: string;
  code: string;
}
export function ForgotPass(params: ForgotPassParameter) {
  return new Promise<ForgotPassResponse>(async (resolve, reject) => {
    if (params.caseName.trim().length === 0)
      return resolve({ status: ForgotPassStatus.UNKNOW_CASE_NAME });

    if (!checkEmail(params.email))
      return resolve({ status: ForgotPassStatus.EMAIL_ERROR });
    if (params.code.trim().length === 0)
      return resolve({ status: ForgotPassStatus.BAD_CODE });
    if (!checkPass(params.password))
      return resolve({ status: ForgotPassStatus.PASSWORD_ERROR });
    if (params.password != params.confirmPass)
      return resolve({ status: ForgotPassStatus.CONFIRM_PASSWORD_ERROR });

    const r: any = await backendApi.patch("/user/password", {
      caseId: params.caseName,
      code: params.code,
      email: params.email,
      password: params.password,
    });

    if (!r.success) {
      if (r.code === 43003)
        return resolve({
          status: ForgotPassStatus.BAD_CODE,
          message: r.message,
        });
      return resolve({
        status: ForgotPassStatus.OTHER_ERROR,
        message: r.message,
      });
    }

    resolve({
      status: ForgotPassStatus.SUCCESS,
    });
  });
}

export interface SendForgotPassCodeParameter {
  caseName: string;
  email: string;
}
export interface SendForgotResponse {
  success: boolean;
  message?: string;
}
export function SendForgotPassCode(params: SendForgotPassCodeParameter) {
  return new Promise<SendForgotResponse>(async (resolve, reject) => {
    const r: any = await backendApi.get(
      "/user/send-reset-password-email-code",
      {
        caseId: params.caseName,
        email: params.email,
      }
    );
    resolve({
      success: r.success,
      message: r.message,
    });
  });
}

export function PostFile(file: File) {
  return new Promise<number>(async (resolve, reject) => {
    const formd = new FormData();
    formd.append("file", file);
    $.ajax({
      url: "https://www.falconchaincheck.com/api/file",
      type: "post",
      contentType: false,
      processData: false,
      // dataType: 'jsonp',
      data: formd,
      success: function (data: any) {
        resolve(parseInt(data.data.file.id));
      },
      error: function () {
        reject();
      },
    });
  });
}
