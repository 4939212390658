import { RootState } from '@/app/store';
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { Search, SearchParams, SearchData } from './SearchAPI';
// import { LoginParameter, LoginResponse, Login } from './loginAPI';

export interface SearchState {
  keyword: string;
  loading: boolean;
  results: Array<SearchData>
}

const initialState: SearchState = {
  keyword: '',
  loading: false,
  results: []
};

export const search = createAsyncThunk(
  'search/search',
  async (failed: SearchParams) => {
    failed.keyword = failed.keyword.trim()
    if ( failed.keyword === "" || failed.keyword.length < 3 ) return [];
    const res: Array<SearchData> = await Search(failed)
    return res
  }
);

export const SearchSlice = createSlice({
  name: 'search',
  initialState,
  // The `reducers` field lets us define reducers and generate associated actions
  reducers: {
  },
  // The `extraReducers` field lets the slice handle actions defined elsewhere,
  // including actions generated by createAsyncThunk or in other slices.
  extraReducers: (builder) => {
    builder
      .addCase(search.pending, (state) => {
        state.loading = true
      })
      .addCase(search.fulfilled, (state, action) => {
        state.loading = false
        state.results = action.payload
      })
      .addCase(search.rejected, (state, action) => {
        state.loading = false
      });
  },
});

export const selectLoading = (state: RootState) => state.search.loading;
export const selectResult = (state: RootState) => state.search.results;

export default SearchSlice.reducer