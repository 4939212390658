import { convertNumber, formatData } from "@/app/util";
import { request } from "@/request";

export interface source {
  address: string,
  amount: number,
  amountUsd: number,
  isContract: boolean,
  linkedTransactionHash: string | null,
}
export interface destinations {
  address: string,
  amount: string,
  amountUsd: string,
  isContract: boolean,
  linkedTransaction: string | null,
}
export interface operationItem {
  blockchain: string,
  chainId: string | null,
  icon: string,
  coinId: number,
  isDeprecated: boolean,
  name: string,
  symbol: string,
  amount: string,
  amountUsd: string,
  destinations: Array<destinations>,
  source: Array<source>
}
export interface TransactionInfo {
  block: number,
  unit: string, // 单位
  amount: string,
  amountUsd: string,
  numberOfConfirmations: number,
  blockchain: string,
  hash: string,
  transactionDate: string,
  signers: Array<string>,
  scoreChainSuccess: string,
  operations: Array<operationItem>
}
export function GetTransactionInfo(params: {hash: string, blockchain: string}) {
  return new Promise<TransactionInfo>(async (resolve, reject) => {
    const r = await request.get<any>(`/v1/blockchains/${params.blockchain}/transactions/${params.hash}`)

    const operations: Array<source> = r.operations

    resolve({
      block: r.block,
      unit: r.fees.unit,
      amount: r.fees.amount,
      amountUsd: convertNumber(r.fees.amountUsd),
      numberOfConfirmations: r.numberOfConfirmations,
      blockchain: r.blockchain,
      hash: r.hash,
      transactionDate: formatData(r.timestamp),
      signers: r.signers[0].addresses,
      scoreChainSuccess: r.status.scorechain,
      operations: operations.map((i: any) => ({
        amount: i.amount,
        amountUsd: convertNumber(i.amountUsd),
        blockchain: i.coin.blockchain,
        chainId: i.coin.chainId,
        icon: i.coin.icon,
        coinId: i.coin.id,
        isDeprecated: i.coin.isDeprecated,
        name: i.coin.name,
        symbol: i.coin.symbol,
        destinations: i.destinations.map((desItem: any) => ({
          address: desItem.address,
          amount: desItem.amount,
          amountUsd: desItem.amountUsd,
          isContract: desItem.isContract,
          linkedTransaction: desItem.linkedTransaction,
        })),
        source: i.sources.map((sourceItem: any) => ({
          address: sourceItem.address,
          amount: sourceItem.amount,
          amountUsd: sourceItem.amountUsd,
          isContract: sourceItem.isContract,
          linkedTransactionHash: sourceItem.linkedTransaction,
        }))
      }))
    })
  });
}