import { useTranslation } from "react-i18next"
import Table, { TableHeader, TableValue } from '@/features/common/table'

import Paging from '@/features/common/paging'

import LoadingIcon from "@/features/common/loading"

import "./assetsList.css"
import { useState } from "react"
import { useAppDispatch, useAppSelector } from "@/app/hooks"
import { GetWallet, selectLoadWalletData, selectSymbol, selectWalletData } from "./addressSlice"
import { useParams } from "react-router-dom"


export default function AddressList() {
  const { t } = useTranslation()

  const [page, setPage] = useState(0)
  const { blockchain } = useParams();


  const walletList = useAppSelector(selectWalletData)
  const loading = useAppSelector(selectLoadWalletData)
  const symbol = useAppSelector(selectSymbol)


  const dispatch = useAppDispatch()

  const header: Array<TableHeader> = [
    {content: t("addressText"), width: "452px",},
    {content: t("balance"), width: "164px",},
    {content: t("address.nOTN"), width: "190px",},
    {content: t("address.info.firstActive"), width: "176px",},
    {content: t("address.info.lastActive"), width: "176px",},
  ]
  let body: Array<Array<TableValue>> = (walletList && !loading) ? walletList.data.map(i => ([
    {content: i.hash, link: `/address/1/${blockchain}/${i.hash}`},
    {content: i.balance + " " + symbol },
    {content: i.numberOfTransactions.toString()},
    {content: i.firstActivityDate},
    {content: i.lastActivityDate},
  ])) : []

  

  const clickButton = (key: number, clickType: number) => {
  }

  const changePage = (page: number) => {
    if (!walletList) return

    dispatch(GetWallet({
      blockchain: walletList.blockchain,
      chainId: walletList.chainId,
      offset: (page - 1) * 4,
      limit: 4
    }))
    setPage(page - 1)
  }

  return (
    <div className="container-item assets-container">
      { loading ? <div className="container-loadicon"><LoadingIcon size={20} color={"#4C6FFF"} /></div> : null }

      <div>
        <p className="assets-title">{t("transaction.addressList")}</p>
        <Table th={header} td={body} clickCallback={clickButton} />
      </div>

      { walletList ? <Paging total={walletList.total} page={page + 1} showCount={4} onChange={(page: number) => changePage(page)} /> : null }
    </div>
  )
}