import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import cn from './locales/zh-cn.json'
const resources = {
  cn: {
    translation: cn
  }
};
i18n.use(initReactI18next) // 将 i18n 向下传递给 react-i18next
  .init({ //初始化
    resources, //本地多语言数据
    fallbackLng: "cn", //默认当前环境的语言
    detection: {
      caches: ['localStorage', 'sessionStorage', 'cookie'],
    }
  })

export default i18n
