import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router";
import "./navigation.css"


export default function Navigation(prop: {id: string}) {
  const { t } = useTranslation()
  
  const [toHome, setToHome] = useState(false);
  const navigate = useNavigate()

  useEffect(() => {
    if (toHome) {
      setTimeout(() => navigate(`/`))
    }
  // eslint-disable-next-line
  }, [toHome])

  return (
    <div className="navigation">
      <span onClick={() => setToHome(true)}>{t("jump.home")}</span>/<span className="active">{prop.id}</span>
    </div>
  )
}