import styles from './user.module.css';
import countriesIcon from '@/assets/china.png'
import { store } from '@/app/store';
import logoBlack from '@/assets/logoBlack.png'
import { useAppDispatch, useAppSelector } from '@/app/hooks';
import { initNotice, selectNotice } from '../alert/alertSlice';
import { useNavigate } from 'react-router-dom';
import { t } from 'i18next';
import { useEffect, useState } from 'react';
import { EventCallback, removeClickEvent, setClickEvent } from '@/app/util';
import { logout } from './Auth';
import { logout as loginLogout } from './userSlice'
import { initBlockChain, selectBlockChain } from '../address/addressSlice';
import noticeImg from '@/assets/notice.png'

let listenClickEvent: EventCallback

export default function Header () {
  const userState = store.getState().user

  const notices = useAppSelector(selectNotice)
  const blockchains = useAppSelector(selectBlockChain)
  const dispatch = useAppDispatch()
  const navigate = useNavigate()
  const [showWin, setShowWin] = useState(false)
  const [showHelpWin, setShowHelpWin] = useState(false)
  const [logined, setLogin] = useState(true)

  useEffect(() => {
    if (!logined) {
      setLogin(true)
      navigate(`/login`)
    }

    if (!notices) {
      dispatch(initNotice())
    }

    if (!blockchains) {
      dispatch(initBlockChain())
    }
  }, [logined, notices, navigate, dispatch])
  
  
  return (
    <div className={styles.header}>
      <div className={styles["header-block"]}>
        <img className={styles["header-logo"]} src={logoBlack} alt="" />
        <div className={styles["header-controls"]}>
          <p className={styles["header-help"]} onClick={() => {
            setShowHelpWin(true)
            removeClickEvent(listenClickEvent)
            listenClickEvent = () => {
              setShowHelpWin(false)
              removeClickEvent(listenClickEvent)
            }
            setClickEvent(listenClickEvent)
          }}>帮助</p>
          {/* <img className={styles["header-countries"]} src={countriesIcon} alt="" /> */}
          <div className={styles["header-divider"]}></div>
          <div className={styles["header-notice"]} onClick={() => navigate(`/alert`)}>
            <img src={noticeImg} alt="" />
            { notices && notices.total > 0 ? <span>{notices.total > 99 ? "99+" : notices.total}</span> : null }
          </div>
          <img onClick={
            () => {
              setShowWin(true)
              removeClickEvent(listenClickEvent)
              listenClickEvent = () => {
                setShowWin(false)
                removeClickEvent(listenClickEvent)
              }
              setClickEvent(listenClickEvent)
            }
          } className={styles["header-avatar"]} src={userState.avatar} alt="" />

          <nav style={{display: showWin !== false ? "block": "none"}} className={styles["avatar-win"]}>
            <li className={styles["align-left"]} title={userState.caseId.toString() || ""} onClick={() => {
            }}>
              {t("help.case")}{userState.caseId}
            </li>
            <li className={styles["align-left"]}>{t("help.email") + userState.email}</li>

            <li onClick={() => {
              dispatch(loginLogout())
              dispatch(logout())
              setTimeout(() => {
                setLogin(false)
              })
            }} >{t("login.logout")}</li>
          </nav>

          <nav style={{display: showHelpWin !== false ? "block": "none"}} className={styles["help-win"]}>
            <li onClick={() => {
              window.open("https://www.falconchaincheck.com/FCC-User-Manual.pdf")
            }} >{t("help.download")}</li>
            <li onClick={() => {
              
            }} className={styles["help-win-info"]} >
              <p className={styles["help-win-title"]}>{t("help.concat")}</p>
              {/* <p>{t("help.mobile")}19328751205</p> */}
              <p>{t("help.email")}support@regtank.com</p>
            </li>
          </nav>
        </div>
      </div>
    </div>
  );
}
