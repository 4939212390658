import { AppThunk, RootState } from '@/app/store';
import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AnalysisType, ObjectType } from '../address/addressAPI';
import { GetAnalysisData, showAssets } from '../address/addressSlice';
import { GetTransactionInfo, TransactionInfo } from './transactionApi';
import { clearInitParams as clearAddressInitParams } from '../address/addressSlice'

export interface TransactionSliceIFace {
  loading: boolean,
  transaction: TransactionInfo | null,
  initParams: initParams
}

const initialState: TransactionSliceIFace = {
  loading: false,
  transaction: null,
  initParams: {
    hash: "",
    blockchain: "",
  }
}

export const GetTransactions = createAsyncThunk(
  'transaction/getData',
  async (failed: initParams) => {
    const transactions = await GetTransactionInfo(failed)
    return transactions
  }
);


export const TransactionSlice = createSlice({
  name: 'transaction',
  initialState,
  // The `reducers` field lets us define reducers and generate associated actions
  reducers: {
    setLoading: (state, action: PayloadAction<boolean>) => {
      state.loading = action.payload
    },

    // 清空数据
    clearInitParams: (state) => {
      state.initParams.blockchain = ""
    },

    setInitParams: (state, action: PayloadAction<initParams>) => {
      state.initParams = {
        blockchain: action.payload.blockchain,
        hash: action.payload.hash,
      }
    },
  },
  // The `extraReducers` field lets the slice handle actions defined elsewhere,
  // including actions generated by createAsyncThunk or in other slices.
  extraReducers: (builder) => {
    builder
    .addCase(GetTransactions.fulfilled, (state, action) => {
      state.loading = false
      state.transaction = action.payload
    })
  },
});

export interface initParams {
  hash: string,
  blockchain: string,
}
export const initTransaction =
  (params: initParams): AppThunk =>
  async (dispatch, getState) => {
    const state = getState().transaction

    // 清空地址数据，避免缓存
    dispatch(clearAddressInitParams())
    
    
    if (!state.loading && (params.blockchain !== state.initParams.blockchain || params.hash !== state.initParams.hash)) {
      dispatch(setInitParams({
        blockchain: params.blockchain,
        hash: params.hash
      }))
      dispatch(setLoading(true))
      dispatch(GetTransactions(params))
      dispatch(GetAnalysisData({
        analysisType: AnalysisType.Incoming,
        objectType: ObjectType.Transaction,
        objectId: params.hash,
        blockchain: params.blockchain,
        coin: showAssets(params.blockchain) ? "ALL" : "MAIN"
      }))
    }
  };

export const { setLoading, clearInitParams, setInitParams } = TransactionSlice.actions

export const selectTransaction = (state: RootState) => state.transaction.transaction;
export const selectLoading = (state: RootState) => state.transaction.loading;

export default TransactionSlice.reducer