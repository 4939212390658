import { useAppSelector } from "@/app/hooks"
import { CoinElm } from "@/features/address/assetsList"
import { selectLoadNotice, selectNotice, translateCondition } from "@/features/alert/alertSlice"
import Table, { TableHeader, TableValue } from "@/features/common/table"
import { useTranslation } from "react-i18next"
import { blockchainTranslate } from "@/features/address/addressSlice"
import { AlertType, ScoringType } from "@/features/alert/alertApi"

export default function AlertNotice (prop: {uploadData: Function}) {
  const { t } = useTranslation()

  const notice = useAppSelector(selectNotice)

  const loading = useAppSelector(selectLoadNotice)

  const header: Array<TableHeader> = [
    {content: t("alertPage.tiggerAt"), width: "160px"},
    {content: t("alertPage.address"), width: "397px",},
    {content: t("alertPage.entityName"), width: "174px",},
    {content: t("alertPage.assetName"), width: "181px",},
    {content: t("alertPage.tigger"), width: "243px",},
  ]

  let body: Array<Array<TableValue>> = notice ? notice.data.map(i => [
    {content: i.date},
    {content: i.alert.address, link: `/address/1/${i.alert.blockchain}/${i.alert.address}`},
    {content: i.alert.entityName},
    {element: <CoinElm coinSrc={`https://app.scorechain.com/images/cryptoicons/${i.alert.blockchain}.svg`} coinName={blockchainTranslate(i.alert.blockchain)} />},
    {content: translateCondition(i.alert.type as AlertType, i.alert.conditions.thresholdScore || 0, i.alert.conditions.scoringType || ScoringType.Incoming)},
  ]) : [[
    {content: t("alert.noAlert")},
    {content: ""},
    {content: ""},
    {content: ""},
    {content: ""},
  ]]

  if (loading) body = [[]]
  
  return <Table th={header} td={body} />
}