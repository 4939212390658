import { request } from "@/request";

export interface SearchParams {
  keyword: string;
}

export enum SearchType {
  Address = 1,
  Transaction = 2,
  Entities = 3,
}

export interface SearchData {
  id: number;
  icon: string;
  name: string;
  type: SearchType;
  blockchain?: string;
}

export function Search(params: SearchParams) {
  return new Promise<Array<SearchData>>(async (resolve, reject) => {
    const result: Array<SearchData> = [];

    try {
      const r = await request.get<any>(
        `/v1/search?q=${params.keyword}&offset=0&limit=10`
      );

      r.addresses.items.forEach((i: any) => {
        result.push({
          id: 0,
          icon: ``,
          name: i.hash,
          blockchain: i.blockchain,
          type: SearchType.Address,
        });
      });

      r.transactions.items.forEach((i: any) => {
        result.push({
          id: 0,
          icon: ``,
          name: i.hash,
          blockchain: i.blockchain,
          type: SearchType.Transaction,
        });
      });

      r.entities.items.forEach((i: any) => {
        result.push({
          id: i.id,
          icon: "",
          name: i.name,
          type: SearchType.Entities,
        });
      });
    } catch (ex) {
      console.log(ex);
    }

    resolve(result);
  });
}
