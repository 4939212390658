import { useState } from "react";
import styled from "./radio.module.css"
import checkedIcon from "@/assets/checked.png"
import uncheckedIcon from "@/assets/unchecked.png"


export default function Radio (prop: {title: string, options: Array<string>, defaultValue?: number, onChange?: Function}) {
  const [selectIndex, changeIndex] = useState(prop.defaultValue || 0)
  // const value = prop.options[selectIndex]
  return (
    <div className={styled.container}>
      <p className={styled.title}>{prop.title}</p>
      <div className={styled.option}>
        {
          prop.options.map((i, k) => (
            <div key={"op" + k} className={styled.item}>
              <div className={styled.opwrap} onClick={() => {
                changeIndex(k)
                prop.onChange && prop.onChange(k)
              }}>
                <img src={selectIndex === k ? checkedIcon : uncheckedIcon} alt="" />
                <span>{i}</span>
              </div>
            </div>
          ))
        }
      </div>
    </div>
  )
}