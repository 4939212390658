import styled from "./address.module.css"
import { Modal } from "react-bootstrap";
import { useState } from "react";
import { useAppDispatch, useAppSelector } from "@/app/hooks";
import { GetAddress, selectAddress } from "@/features/entity/entitySlice";
import { blockchainTranslate } from "@/features/address/addressSlice";
import { t } from "i18next";
import Table, { TableHeader, TableValue } from "@/features/common/table";
import Paging from "@/features/common/paging";

export default function EntityAddress (props: {onClose: Function}) {

  const addressList = useAppSelector(selectAddress)

  const [page, setPage] = useState(0)

  const dispatch = useAppDispatch()

  if (!addressList) return null

  const header: Array<TableHeader> = [
    {content: t("addressText"), width: "613px",},
    {content: t("handleText"), width: "153px",},
  ]
  const body: Array<Array<TableValue>> = addressList.hashList.map((i) => ([
    {content: i},
    {content: t("moreInfo"), link: `/address/1/${addressList.blockchain}/${i}`}
  ]))

  const clickButton = (key: number, clickType: number) => {
    console.log(key, clickType, '！！！！！')
  }

  const changePage = (page: number) => {
    dispatch(GetAddress({
      offset: (page - 1) * 10,
      limit: 10,
      entityId: addressList.entityId,
      entityName: addressList.entityName,
      blockchain: addressList.blockchain,
    }))
    
    setPage(page - 1)
  }

  return (
    <Modal
      show={true} onHide={() => props.onClose()}
      aria-labelledby="contained-modal-title-vcenter"
      size="lg"
      centered
      >
      <Modal.Header closeButton>
        <Modal.Title>
          <div className={styled.addresswintitle}>
            <img src={`https://app.scorechain.com/images/cryptoicons/${addressList.blockchain}.svg`} alt="" />
            <span>{addressList.entityName} - {blockchainTranslate(addressList.blockchain)} {t("entities.wallet")}</span>
          </div>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className={styled.addresswinbody}>
          <p className={styled.adddresstopic}>{t("entities.addressWin.pre")} {blockchainTranslate(addressList.blockchain)} {t("entities.addressWin.detail")}</p>
          <div className={styled.addresstable}>
            <Table th={header} td={body} clickCallback={clickButton} />
          </div>
          <div className={styled.addresslimit}></div>
          <Paging total={addressList.total} page={page + 1} showCount={10} onChange={(page: number) => changePage(page)} />
        </div>
      </Modal.Body>
    </Modal>
  )
}