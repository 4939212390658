import { AppThunk, RootState } from '@/app/store';
import translate from '@/translate';
import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { t } from 'i18next';
import { AlertRes, GetAlerts, GetNotifications, NotificationRes, PatchAlert, PatchParams, DeleteAlert, GetAlertCoins, AlertCoins, AlertType, SetAlertParams, ActiveType, ScoringType, SetAlerts } from './alertApi';

export interface AlertSliceValue {
  alerts: AlertRes | null,
  notifications: NotificationRes | null,
  coins: Array<AlertCoins> | null,
  loading: boolean,
  loadNotification: boolean,
  settingAlert: boolean,
}

const initialState: AlertSliceValue = {
  loading: false,
  loadNotification: false,
  alerts: null,
  notifications: null,
  coins: null,
  settingAlert: false,
};

export const AlertTypeList = [
  AlertType.activity,
  AlertType.scoreThreshold,
  AlertType.lowscoreCoinsReceived,
  AlertType.sendToLowscore,
]
export const ActivityList = [
  ActiveType.all,
  ActiveType.credit,
  ActiveType.debit,
]
export const ScoringList = [
  ScoringType.Incoming,
  ScoringType.Outgoing,
]

export const GetAlert = createAsyncThunk(
  'alert/get',
  async (params: {offset: number, limit: number, address?: string }) => {
    const alerts = GetAlerts(params)
    return alerts
  }
);

export const GetNotification = createAsyncThunk(
  'alert/getNotification',
  async (params: {offset: number, limit: number, address?: string }) => {
    const notice = GetNotifications(params)
    return notice
  }
);

export const PatchStatus = createAsyncThunk(
  'alert/patchStatus',
  async (params: {id: number, data: PatchParams, index: number, type: number}) => {
    await PatchAlert(params.id, params.data)
    return {params}
  }
)

export const DeleteAlerts = createAsyncThunk(
  'alert/deleteAlert',
  async (id: number) => {
    await DeleteAlert(id)
  }
)

export const GetCoins = createAsyncThunk(
  'alert/getAlertCoins',
  async (blockchain: string) => {
    const coins = await GetAlertCoins(blockchain)
    return coins
  }
)

// 设置通知
export const SetAlert = createAsyncThunk(
  'alert/set',
  async (params: SetAlertParams) => {
    try {
      await SetAlerts(params)
      return [2, t("alertPage.created")]
    }
    catch(ex: any) {
      const text = await translate(ex.response.data.message)
      return [1, text]
    }
  }
)

export const AlertSlice = createSlice({
  name: 'alert',
  initialState,
  // The `reducers` field lets us define reducers and generate associated actions
  reducers: {
    setLoading: (state, action: PayloadAction<boolean>) => {
      state.loading = action.payload
    },
    setLoadNotice: (state, action: PayloadAction<boolean>) => {
      state.loadNotification = action.payload
    },
    setAlertStatus: (state, action: PayloadAction<{index: number, type: number}>) => {
      if (!state.alerts) return

      const {index, type} = action.payload;
      if (type === 0) {
        state.alerts.data[index].changingEnable = true;
      } else if (type === 1) {
        state.alerts.data[index].changingEmail = true;
      } else {
        state.alerts.data[index].deleting = true;
      }
    }
  },
  
  extraReducers: (builder) => {
    builder
      .addCase(GetAlert.pending, (state, action) => {
        state.loading = true
      })
      .addCase(GetAlert.fulfilled, (state, action) => {
        state.alerts = action.payload
        state.loading = false
      })
      .addCase(GetNotification.pending, (state, action) => {
        state.loadNotification = true
      })
      .addCase(GetNotification.fulfilled, (state, action) => {
        state.notifications = action.payload
        state.loadNotification = false
      })
      .addCase(GetCoins.fulfilled, (state, action) => {
        state.coins = action.payload
      })
      .addCase(PatchStatus.fulfilled, (state, action) => {
        if (!state.alerts) return

        const params = action.payload.params
        state.alerts.data[params.index][params.type === 0 ? "changingEnable" : "changingEmail"] = false
      })
      .addCase(SetAlert.pending, (state) => {
        state.settingAlert = true;
      })
      .addCase(SetAlert.fulfilled, (state) => {
        state.settingAlert = false;
      })
      .addCase(SetAlert.rejected, (state) => {
        state.settingAlert = false
      })
  },
});

export const initAlert =
  (): AppThunk =>
  async (dispatch, getState) => {
    const loading = selectLoading(getState())

    if (!loading) {
      dispatch(setLoading(true))
      dispatch(GetAlert({offset: 0, limit: 4}));
    }
  };


// 加载通知列表，用于获取通知数量
export const initNotice =
  (): AppThunk =>
  async (dispatch, getState) => {
    const state = getState().alert
    if (!state.loadNotification && !state.notifications) {
      dispatch(setLoadNotice(true))
      dispatch(GetNotification({offset: 0, limit: 4}))
    }
  };

export const { setLoading, setLoadNotice, setAlertStatus } = AlertSlice.actions

export const selectLoading = (state: RootState) => state.alert.loading;
export const selectLoadNotice = (state: RootState) => state.alert.loadNotification;
export const selectAlerts = (state: RootState) => state.alert.alerts;
export const selectNotice = (state: RootState) => state.alert.notifications;
export const selectSettingAlert = (state: RootState) => state.alert.settingAlert;
export const selectCoins = (state: RootState) => state.alert.coins;

export default AlertSlice.reducer


export function translateCondition(alertType: AlertType, thresholdScore: number, scoreType?: ScoringType): string {
  switch(alertType) {
    case AlertType.activity:
      return t("alert.condition.any")
    case AlertType.lowscoreCoinsReceived:
      return `${t("alert.condition.fromwallet")}${thresholdScore}`
    case AlertType.scoreThreshold:
      return `${t("alert.condition.computed")}${t("alert."+(scoreType === ScoringType.Incoming ? "inflows": "outflows"))}${t("alert.condition.scoreBelow")}${thresholdScore}`
    case AlertType.sendToLowscore:
      return `${t("alert.condition.outflowwallet")}${thresholdScore}`
  }
}