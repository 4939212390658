import { Link } from "react-router-dom"
import "./table.css"
import LoadingIcon from "@/features/common/loading"

export interface TableHeader {
  content: string,
  clickable?: boolean,
  callback?: Function,
  width?: string,
  icon?: string
}
export interface TableValue {
  content?: string,
  link?: string
  clickType?: number,
  clickable?: boolean,
  hoverable?: boolean,
  element?: React.ReactElement,
  mutiple?: Array<React.ReactElement | string>
}
export interface TableProps {
  th: Array<TableHeader>
  td: Array<Array<TableValue>>
  emptyText?: string | null
  mutipleTable?: boolean,
  clickCallback?: Function
}

export const loadingElement = {element: <div style={{textAlign: "center"}}><LoadingIcon size={20} color={"#0080FF"} /></div>}

export default function Table (props: TableProps) {
  return (
    <>
      <table>
        <thead>
          <tr>
            {props.th.map((i, k) => 
              <td key={`ttd-${k}`} onClick={() => i.callback?i.callback():null} className={i.clickable ? "active": ""} style={{"width": i.width ? i.width:"auto"}} >
                { i.content}
                {i.icon ? <img src={i.icon} alt="" />:null}
              </td>
            )}
          </tr>
        </thead>
        <tbody className={props.mutipleTable ? "mutiple-body": ""}>
          {props.td.map((i, k) => 
            <tr key={`tr-${k}`}>
              {
                i.map((j, jk) => 
                  <td
                    className={
                      j.mutiple ? "table-define-mutiple" : (j.element ? "table-define-wrap":"")
                    }
                    key={`jk-${jk}`}
                    style={{
                      "width": props.th[jk].width ? props.th[jk].width:"auto"
                    }}
                  >
                    {
                      j.mutiple ?
                        j.mutiple.map((MutipleItem, mutipleKey) => {
                          if (typeof MutipleItem === "string") {
                            return <p key={"mutiple" + mutipleKey}>{MutipleItem}</p>
                          } else {
                            return <div key={"mutiple" + mutipleKey} className="table-define-div">{MutipleItem}</div>
                          }
                        })
                        : 
                        j.element ? 
                          <div className="table-define-div">{j.element}</div>
                          : (j.link ? <Link to={j.link}>{j.content}</Link>
                            : <p  onClick={() => props.clickCallback?props.clickCallback(k, j.clickType):null} className={j.clickable ? "active": (j.hoverable ? "hover": "")}>{j.content}</p>)
                    }
                  </td>
                )
              }
            </tr>
          )}
        </tbody>
      </table>
      {props.emptyText ? <p className="table-errortext">{props.emptyText}</p> : null}
    </>
  )
}