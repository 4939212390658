export function formatNumber(n: number): string {
  if (n < 10) return "0" + n;
  return n.toString();
}

// 将时间戳转换成 年-月-日 时：分：秒
export function formatData(timestamp: number): string {
  const t = timestamp * 1000;
  const d = new Date(t);
  return `${formatNumber(d.getFullYear())}-${formatNumber(
    d.getMonth() + 1
  )}-${formatNumber(d.getDate())} ${formatNumber(d.getHours())}:${formatNumber(
    d.getMinutes()
  )}`;
}

export function numberToString(number: number): string {
  let n = Math.abs(number);
  if (n < 0.01) return "0";
  let stringN = n.toString();
  let decimalIndex = stringN.indexOf(".");
  if (decimalIndex !== -1) stringN = stringN.slice(0, decimalIndex);
  if (stringN.length > 9)
    return (n / Math.pow(10, 9)).toFixed(2).toString() + "B";
  if (stringN.length > 6)
    return (n / Math.pow(10, 6)).toFixed(2).toString() + "M";
  if (stringN.length > 3)
    return (n / Math.pow(10, 3)).toFixed(2).toString() + "K";

  if (decimalIndex !== -1) return n.toFixed(2).toString();
  return n.toString();
}

export function numberToChineseString(number: number): string {
  let n = Math.abs(number);
  if (n < 0.01) return "0";
  let stringN = n.toString();
  let decimalIndex = stringN.indexOf(".");
  if (decimalIndex !== -1) stringN = stringN.slice(0, decimalIndex);
  if (stringN.length > 8)
    return (n / Math.pow(10, 8)).toFixed(2).toString() + "亿";
  if (stringN.length > 4)
    return (n / Math.pow(10, 4)).toFixed(2).toString() + "万";

  if (decimalIndex !== -1) return n.toFixed(2).toString();
  return n.toString();
}

export function convertNumber(number: number): string {
  const str = numberToString(number);
  return `${str !== "0" && number < 0 ? "-" : ""}${str}`;
}
export function convertNumberToChinese(number: number): string {
  if (number === null) return "null";
  const str = numberToChineseString(number);
  return `${str !== "0" && number < 0 ? "-" : ""}${str}`;
}
export type EventCallback = (this: Document, ev: MouseEvent) => any;

export function setClickEvent(func: EventCallback) {
  removeClickEvent(func);
  document.addEventListener("click", func, true);
}

export function removeClickEvent(func: EventCallback) {
  document.removeEventListener("click", func, true);
}

export function checkEmail(email: string): boolean {
  return /^[a-zA-Z0-9._-]+@[a-zA-Z0-9_-]+(\.[a-zA-Z0-9_-]+)+$/.test(email);
}
export function checkPass(pass: string): boolean {
  return /^[\S]{1,16}$/.test(pass);
}

export function isPicture(fileType: string): boolean {
  return (
    [
      "image/gif",
      "image/png",
      "image/jpeg",
      "image/bmp",
      "image/webp",
      "image/x-icon",
      "image/vnd.microsoft.icon",
    ].indexOf(fileType) != -1
  );
}

export function pictureToBase64(file: File) {
  return new Promise<string | ArrayBuffer | null>((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = function () {
      resolve(reader.result);
    };
    reader.onerror = function (error) {
      reject();
    };
  });
}
