import Dashboard from "../common/dashboard"
import PieChart from "../common/pieChart"

import "./riskScore.css"

import RiskScoreList from './riskScoreList'
import { useAppSelector } from "@/app/hooks"
import { selectAnalysisData, selectLoadScorigingAnalysis } from "./addressSlice"



export default function RiskScore (props: {showAssets: boolean, symbol: string, onChangeType: Function, onChangeSelect?: Function}) {
  const analysisData = useAppSelector(selectAnalysisData);
  const loadAnalysisData = useAppSelector(selectLoadScorigingAnalysis);

  return (
    <div className="riskscore-container">
      <div className="left">
        <RiskScoreList symbol={props.symbol} showAssets={props.showAssets} onChangeType={props.onChangeType} onChangeSelect={props.onChangeSelect} />
      </div>
      <div className="right">
        <Dashboard score={analysisData ? analysisData.score : 0} loading={loadAnalysisData} />

        <div className="pie-lmt"></div>

        <PieChart data={analysisData ? analysisData.details : []} loading={loadAnalysisData} icon="https://app.scorechain.com/images/cryptoicons/BITCOIN.svg" />
      </div>
    </div>
  )
}