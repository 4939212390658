import { useEffect, useState } from 'react';

import { useAppDispatch, useAppSelector } from '../../app/hooks';
import {
  selectLoading,
  selectRegisterError,
  emptyRegisterError,
  selectRegistered,
  register,
  clearRegisterFError
} from './userSlice';

import { useTranslation } from 'react-i18next';
import LoadingIcon from "@/features/common/loading"
import UploadComponent from "@/features/common/upload"
import MultipleUploadComponent from "@/features/common/uploadMultiple"
import RegisterSuccess from "@/features/user/RegisterSuccess"

import styles from './user.module.css';
import backPng from "@/assets/back.png"
import { PostFile } from './userAPI';
import { ImgItem } from '../common/uploadMultiple';
import { isPicture, pictureToBase64 } from '@/app/util';
import { useNavigate } from 'react-router';

export default function RegisterInfo() {
  const { t } = useTranslation()

  const loading = useAppSelector(selectLoading);
  const registered = useAppSelector(selectRegistered)
  // const registered = true
  const errors = useAppSelector(selectRegisterError)
  const dispatch = useAppDispatch();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPass, setConfirmPass] = useState("");
  const [name, setName] = useState("");
  const [telephone, setTelephone] = useState("");
  const [unit, setUnit] = useState("");
  const [caseName, setCaseName] = useState("");
  const [IDPicturePositive, setIDPicturePositive] = useState(0);
  const [UPIDPicturePositive, setUPIDPicturePositive] = useState(false);
  const [IDPictureReverse, setIDPictureReverse] = useState(0);
  const [UPIDPictureReverse, setUPsetIDPictureReverse] = useState(false);
  const [seal, setSeal] = useState<Array<ImgItem>>([]);
  const [uploadingSeal, setUploadingSeal] = useState(false);

  const navigator = useNavigate()

  const uploadFile = async (type: number, file: File) => {
    switch(type) {
      case 0:
        setUPIDPicturePositive(true)
        break
      case 1:
        setUPsetIDPictureReverse(true)
        break
    }
    const fileID = await PostFile(file)
    switch(type) {
      case 0:
        setUPIDPicturePositive(false)
        setIDPicturePositive(fileID)
        break
      case 1:
        setUPsetIDPictureReverse(false)
        setIDPictureReverse(fileID)
        break
    }
  }

  const uploadMultipleFile = async (index: number, file: Array<File>) => {
    console.log(index, file)
    const sealCopy = [...seal]
    const sealCount = sealCopy.length
    const uploadSealList = []

    for (let i = 0; i < file.length; i++) {
      // 多选并且当前数量没有超过4个就可以上传
      if (index === -1 && sealCopy.length === 5) break
      const selectFile = file[i]
      let currentIndex = index === -1 ? sealCount + i : index
      let data: ImgItem = {
        id: 0,
        name: '',
        base64: '',
        loading: true,
        error: '',
      }
      // 如果是图片
      if (isPicture(selectFile.type)) {
        const base64 = await pictureToBase64(selectFile)
        if (base64)
          data.base64 = base64 as string
      } else {
        data.name = selectFile.name
      }
      uploadSealList.push(new Promise<void>(async (resolve, reject) => {
        try {
          const id = await PostFile(selectFile)
          sealCopy[currentIndex].id = id
          sealCopy[currentIndex].loading = false
        }
        catch(ex) {
          sealCopy[currentIndex].id = 0
          sealCopy[currentIndex].loading = false
          sealCopy[currentIndex].error = t("register.info.uploadFailure")
        }
        resolve()
      }))
      
      
      if (index === -1) sealCopy.push(data)
      else sealCopy[index] = data
    }
    setSeal(sealCopy)

    Promise.all(uploadSealList).then(() => {
      setSeal([...sealCopy])
      console.log(sealCopy)
    })
  }

  useEffect(() => {
    if (registered) {
      // setTimeout(() => navigate(`/login`))
    }
  }, [registered])

  return (
    <div className={styles["register-wrap"]}>
      {registered ? <RegisterSuccess /> : 
      <>
        <img src={backPng} alt="" className={styles["navigate-icon"]} onClick={() => navigator("/login")} />
        <p className={styles.title}>{t("register.title")}</p>
        <p className={styles["register-item-title"]}>{t("register.progress.setting")}</p>
        <div>
          <p className={styles["login-field-text-" + (errors.emailError !== "" ? "error":"normal")]}>{t("login.text.email")}</p>
          <input
            className={styles["login-input" + (errors.emailError !== "" ? "-error":"")]}
            type="text"
            value={email}
            onChange={(e) => {
              setEmail(e.target.value)
              dispatch(emptyRegisterError())
            }}
            placeholder={t("login.ignore.email")}
          />
          <span className={styles["login-error-text"]}>{errors.emailError}</span>

          <p className={styles["login-field-text-" + (errors.passwordError !== "" ? "error":"normal")]}>{t("login.text.password")}</p>
          <input
            className={styles["login-input" + (errors.passwordError !== "" ? "-error":"")]}
            type="password"
            value={password}
            onChange={(e) => {
              setPassword(e.target.value)
              dispatch(emptyRegisterError())
            }}
            placeholder={t("login.ignore.password")}
          />
          <span className={styles["login-error-text"]}>{errors.passwordError}</span>

          <p className={styles["login-field-text-" + (errors.confirmPasswordError !== "" ? "error":"normal")]}>{t("register.text.confirmPass")}</p>
          <input
            className={styles["login-input" + (errors.confirmPasswordError !== "" ? "-error":"")]}
            type="password"
            value={confirmPass}
            onChange={(e) => {
              setConfirmPass(e.target.value)
              dispatch(emptyRegisterError())
            }}
            placeholder={t("register.ignore.confirmPass")}
          />
          <span className={styles["login-error-text"]}>{errors.confirmPasswordError}</span>

          <div className={styles["login-form-line"]}></div>
          <p className={styles["register-item-title"]}>{t("register.progress.info")}</p>

          <p className={styles["login-field-text-" + (errors.nameError !== "" ? "error":"normal")]}>{t("register.info.name")}</p>
          <input
            className={styles["login-input" + (errors.nameError !== "" ? "-error":"")]}
            type="text"
            value={name}
            onChange={(e) => {
              setName(e.target.value)
              dispatch(emptyRegisterError())
            }}
            placeholder={t("register.info.pleaseName")}
          />
          <span className={styles["login-error-text"]}>{errors.nameError}</span>

          <p className={styles["login-field-text-" + (errors.telephoneError !== "" ? "error":"normal")]}>{t("register.info.telephone")}</p>
          <input
            className={styles["login-input" + (errors.telephoneError !== "" ? "-error":"")]}
            type="text"
            value={telephone}
            onChange={(e) => {
              setTelephone(e.target.value)
              dispatch(emptyRegisterError())
            }}
            placeholder={t("register.info.pleaseTelephone")}
          />
          <span className={styles["login-error-text"]}>{errors.telephoneError}</span>

          <p className={styles["login-field-text-" + (errors.unitError !== "" ? "error":"normal")]}>{t("register.info.unit")}</p>
          <input
            className={styles["login-input" + (errors.unitError !== "" ? "-error":"")]}
            type="text"
            value={unit}
            onChange={(e) => {
              setUnit(e.target.value)
              dispatch(emptyRegisterError())
            }}
            placeholder={t("register.info.pleaseUnit")}
          />
          <span className={styles["login-error-text"]}>{errors.unitError}</span>

          <p className={styles["login-field-text-" + (errors.caseNameError !== "" ? "error":"normal")]}>{t("register.info.caseNumber")}</p>
          <input
            className={styles["login-input" + (errors.caseNameError !== "" ? "-error":"")]}
            type="text"
            value={caseName}
            onChange={(e) => {
              setCaseName(e.target.value)
              dispatch(emptyRegisterError())
            }}
            placeholder={t("register.info.pleaseCaseNumber")}
          />
          <span className={styles["login-error-text"]}>{errors.caseNameError}</span>


          <div className={styles["upload-list"]}>
            <div className={styles["upload-item"]}>
              <UploadComponent
                accept="image/*"
                loading={UPIDPicturePositive} onUpload={(e: File) => {
                  console.log(e)
                  uploadFile(0, e)
                  dispatch(clearRegisterFError({type: 0}))
                }} title={t("register.info.IDPicturePositive")} alertText={errors.IDPositiveError}/>
            </div>

            <div className={styles["upload-item"]}>
              <UploadComponent
                accept="image/*"
                loading={UPIDPictureReverse} onUpload={(e: File) => {
                  console.log(e)
                  uploadFile(1, e)
                  dispatch(clearRegisterFError({type: 1}))
                }} title={t("register.info.IDPictureReverse")} alertText={errors.IDReverseError}/>
            </div>
          </div>

          <MultipleUploadComponent
            data={seal}
            onUpload={uploadMultipleFile}
            onDelete={(index: number) => {
              setSeal([...seal.slice(0, index), ...seal.slice(index+1)])
            }}
            alertText={errors.sealError}
            title={t("register.info.seal")}
            accept="*"
          />

          <div className={styles["login-other-error"]}>{errors.otherError}</div>

          <div
            className={styles["login-button" + (loading ? "-loading": "")]}
            onClick={() => {
              
              if (!loading) dispatch(register({
                email,
                password,
                confirmPass,
                name,
                telephone,
                unit,
                caseName,
                policePositive: IDPicturePositive,
                policeReverse: IDPictureReverse,
                seal: seal.filter(i => i.id !== 0).map(i => (i.id))
              }))
            }}
          >
            { loading ? <LoadingIcon size={20} color={"#fff"} /> : null }
            <span>{t("register.submit")}</span>
          </div>

        </div>
      </>
      }
    </div>
  );
}
