import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router';
import { useAppDispatch, useAppSelector } from '../../app/hooks';
import {
  selectLoading,
  selectForgotPassError,
  sendResetCode,
  changeForgotEmailError,
  emptyForgotError,
  forgotPass,
  selectForgoted,
  changeForgotCaseError
} from './userSlice';
import backPng from "@/assets/back.png"

import { useTranslation } from 'react-i18next';
import LoadingIcon from "@/features/common/loading"

import styles from './user.module.css';
import { checkEmail } from '@/app/util';

export default function Forgot() {
  const { t } = useTranslation()

  const loading = useAppSelector(selectLoading);
  const forgoted = useAppSelector(selectForgoted)
  const errors = useAppSelector(selectForgotPassError)
  const dispatch = useAppDispatch();
  const [getCode, setGetCode] = useState(0)
  const [caseName, setCaseName] = useState('');
  const [code, setCode] = useState("")
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [confirmPass, setConfirmPass] = useState('');
  const navigate = useNavigate()

  useEffect(() => {
    if (forgoted) {
      setTimeout(() => navigate(`/login`))
    }
  // eslint-disable-next-line
  }, [forgoted])

  return (
    <div className={styles.wrap}>
      <img src={backPng} alt="" className={styles["navigate-icon"]} onClick={() => navigate("/login")} />
      <p className={styles.title}>{t("forgot.title")}</p>

      <p className={styles["login-field-text-" + (errors.caseError !== "" ? "error":"normal")]}>{t("login.text.caseNumber")}</p>
      <input
        className={styles["login-input" + (errors.caseError !== "" ? "-error":"")]}
        type="text"
        value={caseName}
        // value="Urvh8Xwj4SHn"
        onChange={(e) => {
          setCaseName(e.target.value)
          dispatch(emptyForgotError())
        }}
        placeholder={t("login.ignore.caseNumber")}
      />
      <span className={styles["login-error-text"]}>{errors.caseError}</span>

      <div>
        <p className={styles["login-field-text-" + (errors.emailError !== "" ? "error":"normal")]}>{t("login.text.email")}</p>

        <div className={styles["login-input-button-wrap"]}>
          <input
            className={styles["login-input-left" + (errors.emailError !== "" ? "-error":"")]}
            type="text"
            value={email}
            onChange={(e) => {
              setEmail(e.target.value)
              dispatch(emptyForgotError())
            }}
            placeholder={t("login.ignore.email")}
          />
          <span
            className={styles["login-input-button" + (getCode === 0 ? "-active":"")]}
            onClick={async () => {
              if (getCode === 0) {
                if (caseName.trim().length === 0) return dispatch(changeForgotCaseError(t("login.ignore.caseNumber")))
                if (!checkEmail(email)) return dispatch(changeForgotEmailError(t("register.format.email")))
                setGetCode(-1)
                // 发送验证码
                const r: any = await dispatch(sendResetCode({
                  caseName,
                  email
                }))
                setGetCode(0)
                if (!r.payload.success) return

                let code = 60
                setGetCode(code--)
                const interval = setInterval(() => {
                  setGetCode(code--)
                  if (code === -1) clearInterval(interval)
                }, 1000)

              }
            }}
          >{t("forgot.getCode")}{getCode > 0 ? `(${getCode}s)` : ""}</span>
        </div>

        <span className={styles["login-error-text"]}>{errors.emailError}</span>

        <p className={styles["login-field-text-" + (errors.codeError !== "" ? "error":"normal")]}>{t("forgot.code")}</p>
        <input
          className={styles["login-input" + (errors.codeError !== "" ? "-error":"")]}
          type="text"
          value={code}
          onChange={(e) => {
            setCode(e.target.value)
            dispatch(emptyForgotError())
          }}
          placeholder={t("forgot.placeCode")}
        />
        <span className={styles["login-error-text"]}>{errors.codeError}</span>

        <p className={styles["login-field-text-" + (errors.passwordError !== "" ? "error":"normal")]}>{t("forgot.newPass")}</p>
        <input
          className={styles["login-input" + (errors.passwordError !== "" ? "-error":"")]}
          type="password"
          value={password}
          onChange={(e) => {
            setPassword(e.target.value)
            dispatch(emptyForgotError())
          }}
          placeholder={t("forgot.placePass")}
        />
        <span className={styles["login-error-text"]}>{errors.passwordError}</span>

        <p className={styles["login-field-text-" + (errors.confirmPasswordError !== "" ? "error":"normal")]}>{t("register.text.confirmPass")}</p>
        <input
          className={styles["login-input" + (errors.confirmPasswordError !== "" ? "-error":"")]}
          type="password"
          value={confirmPass}
          onChange={(e) => {
            setConfirmPass(e.target.value)
            dispatch(emptyForgotError())
          }}
          placeholder={t("register.ignore.confirmPass")}
        />
        <span className={styles["login-error-text"]}>{errors.confirmPasswordError}</span>
 
        <div className={styles["login-other-error"]}>{errors.otherError}</div>
        <div
          className={styles["login-button" + (loading ? "-loading": "")]}
          onClick={() => {
            if (!loading)
              dispatch(forgotPass({email, password, code, confirmPass, caseName }))
          }}
        >
          { loading ? <LoadingIcon size={20} color={"#fff"} /> : null }
          <span>{t("forgot.button")}</span>
        </div>
      </div>
    </div>
  );
}
