import styled from "./alert.module.css"

export default function AlertWin (props: {title?: string, subtitle?: string, content?: string, cancelText?: string, confirmText?: string, confirmCallback?: Function, cancelCallback?: Function}) {
  return <div className={styled.container}>
    <div className={styled.win}>
      <p className={styled.title}>{props.title}</p>
      <p className={styled.subtitle}>{props.subtitle}</p>
      <p className={styled.content}>{props.content}</p>
      <div className={styled.buttonwrap}>
          { props.cancelText ? <button onClick={() => props.cancelCallback && props.cancelCallback()} className="button cancel-button" type="button">{props.cancelText}</button> : null }
          { props.confirmText ? <button onClick={() => props.confirmCallback && props.confirmCallback()} className="button btn btn-danger">{props.confirmText}</button> : null }
        </div>
    </div>
  </div>
}