import { useTranslation } from "react-i18next"
import "./riskAssessment.css"
import securityIcon from "@/assets/security.png"
import warningIcon from "@/assets/warning.png"
import { useAppSelector } from "@/app/hooks"
import { selectEntityInfo } from "../entity/entitySlice"

export default function RiskAssessment () {
  const { t } = useTranslation()
  
  const entityInfo = useAppSelector(selectEntityInfo);
  if (!entityInfo) return null
  
  const riskAssessment = entityInfo.riskAssessment

  return (
    <div className="risk-assessment-container">
      <p className="title">{t("entities.riskAssessment")}</p>

      <ul>
          <li>
            <img src={riskAssessment.isActive ? securityIcon : warningIcon} alt="" />
            <span>{t(riskAssessment.isActive ? "entities.assessment.active" : "entities.assessment.inactive")}</span>
          </li>
          <li>
            <img src={riskAssessment.isP2P ? warningIcon: securityIcon} alt="" />
            <span>{t(riskAssessment.isP2P ? "entities.assessment.allowedP2p" : "entities.assessment.noAllowedP2p")}</span>
          </li>
          <li>
            <img src={riskAssessment.hasAnonymousCoins ? warningIcon : securityIcon} alt="" />
            <span>{t(riskAssessment.hasAnonymousCoins ? "entities.assessment.allowedAnonymous" : "entities.assessment.noAllowedAnonymous")}</span>
          </li>
          <li>
            <img src={riskAssessment.hasBeenHacked ? warningIcon : securityIcon} alt="" />
            <span>{t(riskAssessment.hasBeenHacked ? "entities.assessment.hacked" : "entities.assessment.neverHacked")}</span>
            { riskAssessment.hasBeenHacked ? <p className="detail">{riskAssessment.noteHacks}</p> : null }
          </li>
          <li>
            <img src={riskAssessment.isCashOnly ? warningIcon : securityIcon} alt="" />
            <span>{t(riskAssessment.isCashOnly ? "entities.assessment.allowedCash" : "entities.assessment.noAllowedCash")}</span>
          </li>
      </ul>

    </div>
  )
}