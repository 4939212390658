import { AppThunk, RootState } from "@/app/store";
import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { t } from "i18next";
import {
  LoginParameter,
  LoginResponse,
  Login,
  LoginStatus,
  GetCaseList,
  CaseItem,
  ForgotPassParameter,
  ForgotPass,
  ForgotPassStatus,
  SendForgotPassCodeParameter,
  SendForgotPassCode,
  RegisterParameter,
  Register,
  RegisterStatus,
} from "./userAPI";

export interface LoginState {
  inited: boolean;
  avatar: string;
  token: string;
  email: string;
  password: string;
  status: LoginStatus;
  RegisterStatus: RegisterStatus;
  ForgotStatus: ForgotPassStatus;
  loading: boolean;
  caseList: Array<CaseItem> | null;
  Error: {
    caseError: string;
    emailError: string;
    otherError: string;
    passwordError: string;
  };
  RegisterError: {
    emailError: string;
    passwordError: string;
    confirmPasswordError: string;
    otherError: string;
    nameError: string;
    telephoneError: string;
    unitError: string;
    caseNameError: string;
    IDPositiveError: string;
    IDReverseError: string;
    sealError: string;
  };
  ForgotPassError: {
    caseError: string;
    emailError: string;
    codeError: string;
    passwordError: string;
    confirmPasswordError: string;
    otherError: string;
  };
}

const initialState: LoginState = {
  inited: false,
  avatar: "",
  token: "",
  email: "",
  password: "",
  status: LoginStatus.NORMAL,
  RegisterStatus: RegisterStatus.NORMAL,
  ForgotStatus: ForgotPassStatus.NORMAL,
  loading: false,
  caseList: null,
  Error: {
    caseError: "",
    emailError: "",
    otherError: "",
    passwordError: "",
  },
  RegisterError: {
    emailError: "",
    passwordError: "",
    confirmPasswordError: "",
    otherError: "",
    nameError: "",
    telephoneError: "",
    unitError: "",
    caseNameError: "",
    IDPositiveError: "",
    IDReverseError: "",
    sealError: "",
  },
  ForgotPassError: {
    caseError: "",
    emailError: "",
    codeError: "",
    passwordError: "",
    confirmPasswordError: "",
    otherError: "",
  },
};

export const init = createAsyncThunk("user/init", async () => {
  console.log("user/init");
  const res: Array<CaseItem> = await GetCaseList();
  return res;
});

export const login = createAsyncThunk(
  "user/login",
  async (failed: LoginParameter) => {
    const res: LoginResponse = await Login(failed);
    return res;
  }
);

export const register = createAsyncThunk(
  "user/register",
  async (failed: RegisterParameter) => {
    console.log("user/register");
    const res = await Register(failed);
    return res;
  }
);

export const forgotPass = createAsyncThunk(
  "user/forgetPass",
  async (failed: ForgotPassParameter) => {
    console.log("user/forgetPass");
    const res = await ForgotPass(failed);
    return res;
  }
);

export const sendResetCode = createAsyncThunk(
  "user/sendResetCode",
  async (failed: SendForgotPassCodeParameter) => {
    const res = await SendForgotPassCode(failed);
    return res;
  }
);

export const userSlice = createSlice({
  name: "login",
  initialState,
  // The `reducers` field lets us define reducers and generate associated actions
  reducers: {
    logout: (state) => {
      state.status = LoginStatus.NORMAL;
    },
    emptyRegisterError: (state) => {
      state.RegisterError = {
        emailError: "",
        passwordError: "",
        confirmPasswordError: "",
        otherError: "",
        nameError: "",
        telephoneError: "",
        unitError: "",
        caseNameError: "",
        IDPositiveError: "",
        IDReverseError: "",
        sealError: "",
      };
    },
    emptyLoginError: (state) => {
      state.Error = {
        emailError: "",
        passwordError: "",
        caseError: "",
        otherError: "",
      };
    },
    emptyForgotError: (state) => {
      state.ForgotPassError = {
        emailError: "",
        passwordError: "",
        codeError: "",
        caseError: "",
        confirmPasswordError: "",
        otherError: "",
      };
    },
    changeForgotEmailError: (state, action: PayloadAction<string>) => {
      state.ForgotPassError.emailError = action.payload;
    },
    changeForgotCaseError: (state, action: PayloadAction<string>) => {
      state.ForgotPassError.caseError = action.payload;
    },
    changeInited: (state, action: PayloadAction<boolean>) => {
      state.inited = action.payload;
    },
    resetUserPageStatus: (state) => {
      state.ForgotStatus = ForgotPassStatus.NORMAL;
      state.RegisterStatus = RegisterStatus.NORMAL;
      state.status = LoginStatus.NORMAL;
    },
    clearRegisterFError: (
      state,
      action: PayloadAction<{ type: number; info?: string }>
    ) => {
      switch (action.payload.type) {
        case 0:
          state.RegisterError.IDPositiveError = action.payload.info || "";
          break;
        case 1:
          state.RegisterError.IDReverseError = action.payload.info || "";
          break;
        case 2:
          state.RegisterError.sealError = action.payload.info || "";
          break;
      }
    },
  },
  // The `extraReducers` field lets the slice handle actions defined elsewhere,
  // including actions generated by createAsyncThunk or in other slices.
  extraReducers: (builder) => {
    builder
      .addCase(init.fulfilled, (state, action) => {
        state.caseList = action.payload;
        console.log(action.payload);
      })

      .addCase(login.pending, (state) => {
        console.log(state, "pending");
        state.status = LoginStatus.PROCESSING;
        state.loading = true;
      })
      .addCase(login.fulfilled, (state, action) => {
        state.loading = false;
        state.Error = {
          emailError: "",
          passwordError: "",
          otherError: "",
          caseError: "",
        };
        switch (action.payload.status) {
          case LoginStatus.SUCCESS:
            state.status = LoginStatus.SUCCESS;
            state.avatar = "/avatar.png";
            if (action.payload.token) state.token = action.payload.token;
            break;

          case LoginStatus.EMAIL_ERROR:
            state.Error.emailError = t("login.format.email");
            break;

          case LoginStatus.PASSWORD_ERROR:
            state.Error.passwordError = t("login.format.password");
            break;

          case LoginStatus.UNKNOW_CASE_NAME:
            state.Error.caseError = t("login.ignore.caseNumber");
            break;

          case LoginStatus.OTHER_ERROR:
            if (action.payload.message)
              state.Error.otherError =
                action.payload.message && action.payload.message;
            break;
        }
      })

      .addCase(register.pending, (state) => {
        state.RegisterStatus = RegisterStatus.PROCESSING;
        state.loading = true;
      })
      .addCase(register.fulfilled, (state, action) => {
        state.loading = false;
        state.RegisterError = {
          emailError: "",
          passwordError: "",
          confirmPasswordError: "",
          otherError: "",
          nameError: "",
          telephoneError: "",
          unitError: "",
          caseNameError: "",
          IDPositiveError: "",
          IDReverseError: "",
          sealError: "",
        };
        switch (action.payload.status) {
          case RegisterStatus.SUCCESS:
            state.RegisterStatus = RegisterStatus.SUCCESS;
            break;
          case RegisterStatus.EMAIL_ERROR:
            state.RegisterError.emailError = t("register.format.email");
            state.RegisterError.otherError = t("register.format.email");
            break;
          case RegisterStatus.PASSWORD_ERROR:
            state.RegisterError.passwordError = t("register.format.password");
            state.RegisterError.otherError = t("register.format.password");
            break;
          case RegisterStatus.CONFIRM_PASSWORD_ERROR:
            state.RegisterError.confirmPasswordError = t(
              "register.format.confirmPassword"
            );
            state.RegisterError.otherError = t(
              "register.format.confirmPassword"
            );
            break;
          case RegisterStatus.OTHER_ERROR:
            if (action.payload.message)
              state.RegisterError.otherError =
                action.payload.message && action.payload.message;
            break;
          case RegisterStatus.NAME_ERROR:
            state.RegisterError.nameError = t("register.info.pleaseName");
            break;
          case RegisterStatus.TELEPHONE_ERROR:
            state.RegisterError.telephoneError = t(
              "register.info.pleaseTelephone"
            );
            break;
          case RegisterStatus.UNIT_ERROR:
            state.RegisterError.unitError = t("register.info.pleaseUnit");
            break;
          case RegisterStatus.UNKNOW_CASE_NAME:
            state.RegisterError.caseNameError = t(
              "register.info.pleaseCaseNumber"
            );
            break;
          case RegisterStatus.POLICE_POSITIVE_ERROR:
            state.RegisterError.IDPositiveError = t(
              "register.info.PleaseIDPicturePositive"
            );
            break;
          case RegisterStatus.POLICE_REVERSE_ERROR:
            state.RegisterError.IDReverseError = t(
              "register.info.PleaseIDPictureReverse"
            );
            break;
          case RegisterStatus.POLICE_REVERSE_ERROR:
            state.RegisterError.sealError = t("register.info.PleaseSeal");
            break;
        }
      })

      .addCase(forgotPass.pending, (state) => {
        console.log(state, "pending");
        state.ForgotStatus = ForgotPassStatus.PROCESSING;
        state.loading = true;
      })
      .addCase(forgotPass.fulfilled, (state, action) => {
        state.loading = false;
        state.ForgotPassError = {
          emailError: "",
          passwordError: "",
          codeError: "",
          caseError: "",
          confirmPasswordError: "",
          otherError: "",
        };
        switch (action.payload.status) {
          case ForgotPassStatus.SUCCESS:
            state.ForgotStatus = ForgotPassStatus.SUCCESS;
            break;
          case ForgotPassStatus.EMAIL_ERROR:
            state.ForgotPassError.emailError = t("register.format.email");
            break;
          case ForgotPassStatus.PASSWORD_ERROR:
            state.ForgotPassError.passwordError = t("register.format.password");
            break;
          case ForgotPassStatus.CONFIRM_PASSWORD_ERROR:
            state.ForgotPassError.confirmPasswordError = t(
              "register.format.confirmPassword"
            );
            break;
          case ForgotPassStatus.UNKNOW_CASE_NAME:
            state.ForgotPassError.caseError = t("login.ignore.caseNumber");
            break;
          case ForgotPassStatus.BAD_CODE:
            state.ForgotPassError.codeError = action.payload.message
              ? action.payload.message
              : t("forgot.placeCode");
            break;
          case ForgotPassStatus.OTHER_ERROR:
            if (action.payload.message)
              state.ForgotPassError.otherError =
                action.payload.message && action.payload.message;
            break;
        }
      })

      .addCase(sendResetCode.fulfilled, (state, action) => {
        if (!action.payload.success && action.payload.message)
          state.ForgotPassError.emailError = action.payload.message;
      });
  },
});

export const selectLoading = (state: RootState) => state.login.loading;
export const selectLogin = (state: RootState) =>
  state.login.status === LoginStatus.SUCCESS;
export const selectAvatar = (state: RootState) => state.login.avatar;
export const selectToken = (state: RootState) => state.login.token;
export const selectError = (state: RootState) => state.login.Error;
export const selectRegisterError = (state: RootState) =>
  state.login.RegisterError;
export const selectForgotPassError = (state: RootState) =>
  state.login.ForgotPassError;
export const selectRegistered = (state: RootState) =>
  state.login.RegisterStatus === RegisterStatus.SUCCESS;
export const selectForgoted = (state: RootState) =>
  state.login.ForgotStatus === ForgotPassStatus.SUCCESS;
export const selectCaseList = (state: RootState) => state.login.caseList;
export const selectInited = (state: RootState) => state.login.inited;

export const initUserConfig = (): AppThunk => async (dispatch, getState) => {
  const state = getState().login;
  if (!state.inited) {
    dispatch(changeInited(true));

    // 去除案件信息获取
    // dispatch(init());
  }
};

export default userSlice.reducer;

export const {
  emptyRegisterError,
  emptyLoginError,
  emptyForgotError,
  changeForgotEmailError,
  changeForgotCaseError,
  changeInited,
  logout,
  resetUserPageStatus,
  clearRegisterFError,
} = userSlice.actions;
