import { useState } from "react";
import styled from "./select.module.css"
import bottomIcon from "@/assets/bottom.png"
import { EventCallback, removeClickEvent, setClickEvent } from "@/app/util";

export interface selectCptOption {
  content: string,
  image?: string,
}

let listenClickEvent: EventCallback

export default function Select(prop: {title?: string, options: Array<selectCptOption>, defaultValue?: number, onSelect?: Function}) {
  const [selectIndex, changeIndex] = useState(prop.defaultValue || 0)
  const [showSelector, setShowSelector] = useState(false)
  const [lock, setlock] = useState(false)
  const value = prop.options[selectIndex]
  return (
    <div className={styled.container}>
      {styled.title ? <p className={styled.title}>{prop.title}</p> : null}
      <div className={styled.select} onClick={()=> {
        if (lock) return
        setlock(true)

        setShowSelector(true)
        removeClickEvent(listenClickEvent)

        listenClickEvent = (e) => {
          removeClickEvent(listenClickEvent)
          setTimeout(() => {
            setlock(false)
            setShowSelector(false)
          })
        }

        setClickEvent(listenClickEvent)
      }}>
        <div className={styled.content}>
          { value && value.image ? <img src={value.image} alt="" /> : null }
          <span>{value && value.content}</span>
        </div>
        <img className={styled.selectIcon} src={bottomIcon} alt="" />
      </div>
      
      {showSelector ? (
        <div className={styled.option}>
          {
            prop.options.map((i, k) => (
              <div key={`skey${k}`} className={styled.content} onClick={() => {
                prop.onSelect && prop.onSelect(k)
                setShowSelector(false)
                changeIndex(k)
              }}>
                { i.image ? <img src={i.image} alt="" /> : null }
                <span>{i.content}</span>
              </div>
            ))
          }
        </div>
      ) : null}
    </div>
  )
}