import { useTranslation } from "react-i18next";
import "./paging.css"

import LeftIcon from '@/assets/left.png'
import LeftAIcon from '@/assets/leftA.png'
import RightIcon from '@/assets/right.png'
import RightAIcon from '@/assets/rightA.png'
import MoreIcon from '@/assets/moreIcon.png'

export interface PagingProps {
  total: number,
  showCount: number,
  page: number,
  onChange: Function
}

export default function Paging (prop: PagingProps) {
  const { t } = useTranslation()
  const totalPage = Math.ceil(prop.total / prop.showCount)

  const setPage = (page: number) => {
    prop.onChange(page)
  }

  return (
    <div className="paging">
      <p className="result-text">{prop.total}{t("resultCount")}</p>
      <div className="divider"></div>
      <div className="paging-button" onClick={()=>prop.page > 1 ? setPage(prop.page - 1) : null}>
        <img src={prop.page > 1 ? LeftAIcon : LeftIcon} alt="" />
      </div>

      <div onClick={()=>setPage(1)} className={`paging-button ${1 === prop.page ? "active": ""}`}>
        1
      </div>

      {
        totalPage > 5 && prop.page > 3 ? 
          <div className="paging-button normal">
            <img src={prop.page > 1 ? MoreIcon : MoreIcon} alt="" />
          </div> : null
      }
      
      {
        totalPage > 2 ?
          Array.from({length: Math.min(totalPage - 2, 3)}, ((v,k) =>{
            let kV = k + 2;
            if (totalPage > 4 && prop.page > 3) {
              kV += prop.page > totalPage - 3 ? totalPage - 5: prop.page - 3
            }
            return <div key={`key-${k}`} onClick={()=>setPage(kV)} className={`paging-button ${kV === prop.page ? "active": ""}`}>
              {kV}
            </div>
          })) : null
      }

      {
        totalPage > 5 && prop.page < totalPage - 2 ? 
          <div className="paging-button normal">
            <img src={prop.page > 1 ? MoreIcon : MoreIcon} alt="" />
          </div> : null
      }

      {
        totalPage > 1 ?
          <div onClick={()=>setPage(totalPage)} className={`paging-button ${totalPage === prop.page ? "active": ""}`}>
            {totalPage}
          </div> : null
      }

      <div className="paging-button"  onClick={()=>prop.page < totalPage ? setPage(prop.page + 1) : null}>
        <img src={prop.page < totalPage ? RightAIcon : RightIcon} alt="" />
      </div>
    </div>
  )
}