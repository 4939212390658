import { useAppDispatch, useAppSelector } from "@/app/hooks"
import { CoinElm } from "@/features/address/assetsList"
import { DeleteAlerts, PatchStatus, selectAlerts, selectLoading, setAlertStatus, translateCondition } from "@/features/alert/alertSlice"
import Table, { TableHeader, TableValue, loadingElement } from "@/features/common/table"
import { useTranslation } from "react-i18next"
import enableImg from "@/assets/enable.png"
import disableImg from "@/assets/disable.png"
import { AlertItem, AlertType, ScoringType } from "@/features/alert/alertApi"
import { useState } from "react"
import AlertWin from "@/features/common/alert"
import { blockchainTranslate, selectBlockChain } from "@/features/address/addressSlice"

export default function AlertSetting (prop: {uploadData: Function}) {
  const { t } = useTranslation()

  const alerts = useAppSelector(selectAlerts)
  const blockchains = useAppSelector(selectBlockChain);


  const dispatch = useAppDispatch()

  const [deleteKey, setDeleteKey] = useState(-1)

  const loading = useAppSelector(selectLoading)

  const toggleStatus = async (i: AlertItem, k: number, type: number) => {
    dispatch(setAlertStatus({
      index: k,
      type
    }))
    if (type === 0) {
      await dispatch(PatchStatus({
        id: i.id,
        data: {
          isEnabled: !i.isEnabled,
          isMailEnabled: !i.isEnabled
        },
        index: k,
        type: 0
      }))
    } else {
       await dispatch(PatchStatus({
        id: i.id,
        data: {
          isMailEnabled: !i.isMailEnabled
        },
        index: k,
        type: 1
      }))
    }
    prop.uploadData()
  }

  const header: Array<TableHeader> = [
    {content: t("alertPage.createdAt"), width: "160px"},
    {content: t("alertPage.address"), width: "237px",},
    {content: t("alertPage.entityName"), width: "94px",},
    {content: t("alertPage.assetName"), width: "131px",},
    {content: t("alertPage.tigger"), width: "232px",},
    {content: t("alertPage.enable"), width: "103px",},
    {content: t("alertPage.email"), width: "102px",},
    {content: t("alertPage.handle"), width: "103px",},
  ]

  // PatchAlert
  let body: Array<Array<TableValue>> = alerts ? alerts.data.map((i, k) => [
    {content: i.creationDate},
    {content: i.address, link: `/address/1/${i.blockchain}/${i.address}`},
    {content: i.entityName},
    {element: <CoinElm coinSrc={blockchains ? `https://app.scorechain.com/images/cryptoicons/${blockchains[i.blockchain].icon}` : ""} coinName={ blockchainTranslate(i.blockchain) } />},
    {content: translateCondition(i.type as AlertType, i.conditions.thresholdScore || 0, i.conditions.scoringType || ScoringType.Incoming)},
    i.changingEnable ? loadingElement : {element: <img alt="" className="clickable" src={i.isEnabled ? enableImg: disableImg} onClick={() => toggleStatus(i, k, 0)} />},
    i.changingEmail ? loadingElement : {element: <img alt="" className="clickable" src={i.isMailEnabled ? enableImg: disableImg} onClick={() => toggleStatus(i, k, 1)} />},
    i.deleting ? loadingElement : {content: "删除", clickable: true, clickType: 1},
  ]) : []

  
  if (!loading && body.length === 0) {
    body = [[
      {content: t("alert.noAlert")},
      {content: ""},
      {content: ""},
      {content: ""},
      {content: ""},
      {content: ""},
      {content: ""},
      {content: ""},
    ]]
  }
  

  const clickButton = (key: number, clickType: number) => {
    if (clickType === 1) {
      setDeleteKey(key)
    }
  }

  return <>
    <Table th={header} td={body} clickCallback={clickButton} />
    { deleteKey !== -1 ? <AlertWin title={t("alertPage.deleteTitle")} subtitle={t("alertPage.confirmText")} content={t("alertPage.confirmAllText")} cancelText={t("alertPage.cancel")} confirmText={t("alertPage.remove")} confirmCallback={
      async () => {
        if (!alerts) return

        setDeleteKey(-1)
        dispatch(setAlertStatus({
          index: deleteKey,
          type: 2
        }))
        await dispatch(DeleteAlerts(alerts.data[deleteKey].id))
        prop.uploadData()
      }
    } cancelCallback={
      () => setDeleteKey(-1)
    } /> : null }
  </>
}