import LoginAside from '@/assets/loginAsidePic.png'
import LoginAsideIcon from '@/assets/loginAsideIcon.png'
import logoWhite from '@/assets/logoWhite.png'
import '../Login/Login.css';
import { initUserConfig, resetUserPageStatus, selectInited } from '@/features/user/userSlice';
import { useAppDispatch, useAppSelector } from '@/app/hooks';
import { useEffect, useState } from 'react';
import Register from '@/features/user/Register';

function RegisterPage() {
  const dispatch = useAppDispatch()
  const inited = useAppSelector(selectInited)

  useEffect(() => {
    if (!inited ) {
      dispatch(initUserConfig())
    }
    dispatch(resetUserPageStatus())
  })
  

  document.title = "Falconchain Check Login"
  
  return (
    <div className="login-container">
      <aside>
        <img className='aside-logo' src={logoWhite} alt="" />
        <img className='aside' src={LoginAside} alt="" />
        <img className='aside-icon' src={LoginAsideIcon} alt="" />
      </aside>
      <div className='form-wrap'>
        <Register />
      </div> 
    </div>
  );
}

export default RegisterPage;

